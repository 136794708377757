import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import ava01 from "../../assets/all-images/testimonial-1.webp";
import ava02 from "../../assets/all-images/testimonial-4.jpeg";
import ava03 from "../../assets/all-images/testimonial-3.jpeg";
import ava04 from "../../assets/all-images/testimonial-2.jpeg";
import { Container } from "reactstrap";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Container>
        <Slider {...settings}>
          <div className="testimonial py-4 px-3">
            <p className="section__description">
              Love the simplicity of the service and the prompt customer
              support. We can’t imagine working without it.
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
              <img src={ava01} alt="" className="w-25 h-25 rounded-2" />

              <div>
                <h6 className="mb-0 mt-3">Francine Jean-Louis</h6>
                <p className="section__description">Customer</p>
              </div>
            </div>
          </div>

          <div className="testimonial py-4 px-3">
            <p className="section__description">
              Over the years, I've been incredibly satisfied with Bitrades Investments services, and my personal finance has
              remained secure to the point where I can confidently look to the
              future, knowing that my financial goals have been met.
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
              <img src={ava02} alt="" className="w-25 h-25 rounded-2" />

              <div>
                <h6 className="mb-0 mt-3">Larry Peters</h6>
                <p className="section__description">Customer</p>
              </div>
            </div>
          </div>

          <div className="testimonial py-4 px-3">
            <p className="section__description">
              Bitrades Investments has taken the time to explain every detail and
              answer any query I've had from my initial investment with them.
              I've invested a major portion of my retirement with them over the
              years, and they've delivered more than I expected, and I'm looking
              forward to many more excellent years with them.
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
              <img src={ava03} alt="" className="w-25 h-25 rounded-2" />

              <div>
                <h6 className="mb-0 mt-3">Phoebe Crews</h6>
                <p className="section__description">Customer</p>
              </div>
            </div>
          </div>

          <div className="testimonial py-4 px-3">
            <p className="section__description">
              There are two reasons why I invest with Bitrades
              Investments Limited. They find fantastic investment packages that
              provide a solid return on investment as well as a high level of
              trust. You can conduct all the due diligence you want, but who you
              invest with ultimately determines your financial success.
            </p>

            <div className="mt-3 d-flex align-items-center gap-4">
              <img src={ava04} alt="" className="w-25 h-25 rounded-2" />

              <div>
                <h6 className="mb-0 mt-3">Ted McVeigh</h6>
                <p className="section__description">Customer</p>
              </div>
            </div>
          </div>
        </Slider>
      </Container>
    </Container>
  );
};

export default Testimonial;
