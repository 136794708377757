import React, { useState } from "react";
import { BrandLogo } from "../brandLogo/";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import "../../styles/navBar.css";

import { MenuAlt3Icon, XIcon } from "@heroicons/react/outline";
// import { Marginer } from ".";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  const navLinks = [
    {
      path: "/home",
      display: "HOME",
    },
    {
      path: "/about",
      display: "ABOUT",
    },
    {
      path: "/services",
      display: "SERVICES",
    },
    {
      path: "/pricing",
      display: "PRICING",
    },

    {
      path: "/faq",
      display: "FAQ",
    },
    {
      path: "/contact",
      display: "CONTACT",
    },
  ];

  const LinkItem = styled.li`
    height: 60%;
    padding: 0 1.1em;
    color: #2b076e;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    transition: all 220ms ease-in-out;
  `;

  const Links = styled(Link)`
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    &:hover {
      color: #f9a827;
    }
  `;
  const LinkButton = styled.button`
    border: 0;
    outline: 0;
    padding: 8px 1em;
    color: #2b076e;
    font-size: 13px;
    font-weight: 600;
    border-radius: 20px;
    background-color: transparent;
    border: 2px solid #eebc1d;
    transition: all 240ms ease-in-out;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #2b076e;
    }
    &:not(:last-of-type) {
      margin-right: 7px;
    }
  `;
  const LinkButton1 = styled.button`
    border: 0;
    outline: 0;
    padding: 8px 1em;
    color: #2b076e;
    font-size: 13px;
    font-weight: 600;
    border-radius: 20px;
    background-image: linear-gradient(to right, transparent 0%, #00c9ff 100%);
    border: 2px solid #eebc1d;
    transition: all 240ms ease-in-out;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #2b076e;
    }
    &:not(:last-of-type) {
      margin-right: 7px;
    }
  `;
  const LoginButton = styled.button`
    border: 0;
    outline: 0;
    padding: 8px 1em;
    color: #2b076e;
    font-size: 13px;
    font-weight: 600;
    border-radius: 20px;
    margin-top: 10px;
    background-color: transparent;
    border: 2px solid #00c9ff;
    transition: all 240ms ease-in-out;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #2b076e;
    }
    &:not(:last-of-type) {
      margin-right: 7px;
    }
  `;


  return (
    <>
      <div className="w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg shadow-lg shadow-blue-500/50 ">
        <div className="px-2 flex justify-between items-center w-full h-full">
          <div className="flex items-center">
            <div className="logo">
              <h1>
                <Link to="/home">
                  <BrandLogo />
                </Link>
              </h1>
            </div>
            <ul className="hidden md:flex uppercase">
              <li className="text-[#2B076E] cursor-pointer">
                <div className="navigation__wrapper d-flex align-items-center justify-content-between">
                  <div className="menu">
                    {navLinks.map((item, index) => (
                      <NavLink
                        to={item.path}
                        className={(navClass) =>
                          navClass.isActive
                            ? "nav__active nav__item"
                            : "nav__item"
                        }
                        key={index}
                      >
                        {item.display}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </li>
              {/* <Marginer />
              <div className="search__box">
                <input type="text" placeholder="Search" />
                <span>
                  <i class="ri-search-line"></i>
                </span>
              </div> */}
            </ul>
          </div>

          <div
            className="md:hidden mr-4 transition duration-150 ease-out"
            onClick={handleClick}
          >
            {!nav ? (
              <MenuAlt3Icon className="w-5" />
            ) : (
              <XIcon className="w-5" />
            )}
          </div>
        </div>

        <ul
          className={
            !nav
              ? "hidden"
              : "fixed uppercase bg-zinc-200 w-full px-8 transition duration-150 ease-out "
          }
        >
          <LinkItem>
            <Links
              onClick={handleClose}
              to="/home"
              smooth={true}
              duration={500}
            >
              <LinkButton>Home</LinkButton>
            </Links>
          </LinkItem>

          <LinkItem>
            <Links
              onClick={handleClose}
              to="/about"
              smooth={true}
              duration={500}
            >
              <LinkButton>About</LinkButton>
            </Links>
          </LinkItem>

          <LinkItem>
            <Links
              onClick={handleClose}
              to="/services"
              smooth={true}
              duration={500}
            >
              <LinkButton>Services</LinkButton>
            </Links>
          </LinkItem>

          <LinkItem>
            <Links
              onClick={handleClose}
              to="/pricing"
              smooth={true}
              duration={500}
            >
              <LinkButton>Pricing</LinkButton>
            </Links>
          </LinkItem>

          <LinkItem>
            <Links onClick={handleClose} to="/faq" smooth={true} duration={500}>
              <LinkButton>Faq</LinkButton>
            </Links>
          </LinkItem>

          <LinkItem>
            <Links
              onClick={handleClose}
              to="/contact"
              smooth={true}
              duration={500}
            >
              <LinkButton>Contact</LinkButton>
            </Links>
          </LinkItem>

          <div className="flex flex-col my-4">
            <Link to="/register">
              <LinkButton1> Register</LinkButton1>
            </Link>
            <Link to="/login">
              <LoginButton>Login</LoginButton>
            </Link>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
