import React from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";

const CoinItem = ({ coin }) => {
  const {
    market_cap_rank,
    image,
    id,
    name,
    symbol,
    current_price,
    price_change_percentage_24h,
    total_volume,
    market_cap,
    sparkline_in_7d,
  } = coin;

  const priceChangeColor =
    price_change_percentage_24h > 0 ? "text-green-600" : "text-red-600";

  return (
    <tr className="h-[80px] border-b overflow-hidden">
      <td>{market_cap_rank}</td>
      <td>
        <div className="flex items-center">
          <img className="w-6 mr-2 rounded-full" src={image} alt={id} />
          <p className="hidden sm:table-cell">{name}</p>
        </div>
      </td>
      <td>{symbol.toUpperCase()}</td>
      <td>${current_price.toLocaleString()}</td>
      <td>
        <p className={priceChangeColor}>
          {price_change_percentage_24h.toFixed(2)}%
        </p>
      </td>
      <td className="w-[180px] hidden md:table-cell">
        ${total_volume.toLocaleString()}
      </td>
      <td className="w-[180px] hidden sm:table-cell">
        ${market_cap.toLocaleString()}
      </td>
      <td>
        <Sparklines data={sparkline_in_7d.price}>
          <SparklinesLine color="teal" />
        </Sparklines>
      </td>
    </tr>
  );
};

export default CoinItem;
