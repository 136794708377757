import React, { Fragment, useState } from "react";
import Helmet from "../../../../Helmet/Helmet";
import "../../MainDash.css";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../../../../../contexts/AuthContext";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { changePassword } = useAuth();

  const areAllFieldsFilled = oldPassword !== "" && newPassword !== "";

  const handleChangePassword = async () => {
    try {
      await changePassword(oldPassword, newPassword);
      setIsOpen(true);
      setOldPassword("");
      setNewPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <Helmet title="Change Password">
      <div className="MainDash">
        <h2 className="mt-20  ml-5">Change Your Password</h2>
      </div>
      <div className="min-h-screen  flex flex-col justify-center py-12 px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-8 shadow rounded-lg sm:px-10">
            <form className="mb-0 space-y-8">
              <div>
                <label
                  htmlFor="old-password"
                  className="block text-sm font-medium text-[#000D67]"
                >
                  Old Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="old-password"
                    name="old-password"
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    className="px-4 py-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={toggleShowOldPassword}
                  >
                    {showOldPassword ? (
                      <svg
                        className="h-5 w-5 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.772 14.59a3 3 0 004.32-4.182l1.417-1.417a5 5 0 00-7.154-7.154l-1.417 1.417a3 3 0 00-4.32 4.182l-1.417 1.417a5 5 0 007.154 7.154l1.417-1.417z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="h-5 w-5 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M4.65 14.35a8 8 0 1010.707-10.707l-1.417 1.417a6 6 0 11-8.464 8.464l-1.417 1.417zm1.414-1.414l8.464-8.464-1.414-1.414-8.464 8.464 1.414 1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>

              <div>
                <label
                  htmlFor="new-password"
                  className="block text-sm font-medium text-[#000D67]"
                >
                  New Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="new-password"
                    name="new-password"
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    className="px-4 py-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={toggleShowNewPassword}
                  >
                    {showNewPassword ? (
                      <svg
                        className="h-5 w-5 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.772 14.59a3 3 0 004.32-4.182l1.417-1.417a5 5 0 00-7.154-7.154l-1.417 1.417a3 3 0 00-4.32 4.182l-1.417 1.417a5 5 0 007.154 7.154l1.417-1.417z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="h-5 w-5 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M4.65 14.35a8 8 0 1010.707-10.707l-1.417 1.417a6 6 0 11-8.464 8.464l-1.417 1.417zm1.414-1.414l8.464-8.464-1.414-1.414-8.464 8.464 1.414 1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={handleChangePassword}
                  disabled={!areAllFieldsFilled}
                  className="w-full cursor-pointer flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-2/3 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Password Successfully Changed!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p>Please use your new password on your next login</p>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Helmet>
  );
};

export default ChangePassword;
