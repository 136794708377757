import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import Helmet from "../../../../Helmet/Helmet";
import "../../MainDash.css";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { useAuth } from "../../../../../contexts/AuthContext";

const TransactionHistory = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();
  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        dbStateRef.current = doc.data();
        setDbState(dbStateRef.current);
      });

      return () => unsub();
    }
  }, [currentUser]);

  const itemsPerPage = 5; // Change this to adjust the number of items per page

  const getPaginatedData = (data) => {
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const paginatedData = Array.from({ length: pageCount }, (_, i) =>
      data.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage)
    );
    return paginatedData;
  };

  const dhistory = getPaginatedData(
    Array.from({ length: 20 }, (_, i) => ({
      id: i + 1,
      date: dbState?.[`ddate${i + 1}`],
      amount: dbState?.[`damount${i + 1}`],
      status: dbState?.[`dstatus${i + 1}`],
      type: dbState?.[`dtype${i + 1}`],
    }))
  );

  const whistory = getPaginatedData(
    Array.from({ length: 20 }, (_, i) => ({
      id: i + 1,
      date: dbState?.[`wdate${i + 1}`],
      amount: dbState?.[`wamount${i + 1}`],
      status: dbState?.[`wstatus${i + 1}`],
      type: dbState?.[`wtype${i + 1}`],
    }))
  );

  const Pagination = ({ currentPage, totalPage, onPageChange }) => (
    <div className="flex justify-center mt-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="mr-2 px-3 py-1 bg-gray-200 text-gray-600 rounded"
      >
        Previous
      </button>
      <span className="mx-2">
        Page {currentPage} of {totalPage}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPage}
        className="ml-2 px-3 py-1 bg-gray-200 text-gray-600 rounded"
      >
        Next
      </button>
    </div>
  );

  const [dcurrentPage, setDCurrentPage] = useState(1);
  const [wcurrentPage, setWCurrentPage] = useState(1);

  return (
    <Helmet title="Transaction History">
      <div className="MainDash">
        <h2 className="mt-20 ml-5">Transactions On Your Account</h2>
      </div>
      <div className="w-full px-2 ">
        <Tab.Group>
          <Tab.List className="flex space-x-1 p-1">
            <Tab
              className={({ selected }) =>
                classNames(
                  "rounded-lg px-5 pl-5 pr-5  text-sm font-bold leading-5 text-[#3754A5]",
                  "ring-[#FBC1BF] ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-0",
                  selected ? "bg-[#FBC1BF] font-semibold" : "text-blue-200"
                )
              }
            >
              DEPOSITS
            </Tab>

            <Tab
              className={({ selected }) =>
                classNames(
                  "rounded-lg py-2.5 pl-5 pr-5 text-sm font-bold leading-5 text-[#3754A5]",
                  "ring-[#FBC1BF] ring-opacity-60 ring-offset-2  focus:outline-none focus:ring-0",
                  selected ? "bg-[#FBC1BF] font-semibold" : "text-blue-200 "
                )
              }
            >
              WITHDRAWALS
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel>
              <div className="relative overflow-x-auto  w-fill">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        DATE
                      </th>
                      <th scope="col" className="px-6 py-3">
                        AMOUNT
                      </th>
                      <th scope="col" className="px-6 py-3">
                        STATUS
                      </th>
                      <th scope="col" className="px-6 py-3">
                        TYPE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dhistory[dcurrentPage - 1]?.map((transaction) => (
                      <tr key={transaction.id} className="bg-white border-b">
                        <td className="px-6 py-4">{transaction.date}</td>
                        <td className="px-6 py-4">{transaction.amount}</td>
                        <td
                          className={`px-6 py-4 ${
                            transaction.status === "success"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {transaction.status}
                        </td>
                        <td className="px-6 py-4">{transaction.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={dcurrentPage}
                  totalPage={dhistory.length}
                  onPageChange={setDCurrentPage}
                />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="relative overflow-x-auto  w-fill">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        DATE
                      </th>
                      <th scope="col" className="px-6 py-3">
                        AMOUNT
                      </th>
                      <th scope="col" className="px-6 py-3">
                        STATUS
                      </th>
                      <th scope="col" className="px-6 py-3">
                        TYPE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {whistory[wcurrentPage - 1]?.map((transaction) => (
                      <tr key={transaction.id} className="bg-white border-b">
                        <td className="px-6 py-4">{transaction.date}</td>
                        <td className="px-6 py-4">{transaction.amount}</td>
                        <td
                          className={`px-6 py-4 ${
                            transaction.status === "success"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {transaction.status}
                        </td>
                        <td className="px-6 py-4">{transaction.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={wcurrentPage}
                  totalPage={whistory.length}
                  onPageChange={setWCurrentPage}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Helmet>
  );
};

export default TransactionHistory;
