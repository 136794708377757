import React from "react";

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";

import { Container, Row, Col } from "reactstrap";
import AboutSection from "../components/UI/AboutSection";
import ServicesList from "../components/UI/ServicesList";
import Services from "../components/UI/services/Services";
import Testimonial from "../components/UI/Testimonial";
import Featured from "../components/UI/Featured";
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Footer/Footer";
import BackTopBtn from "../components/BackTopbtn";
import ContactHome from "./ContactHome";
import styled from "styled-components";

const Home = () => {
  const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `;

  return (
    <Helmet title="Home">
      <PageContainer>
        <Navbar />

        {/* ============= hero section =========== */}

        <HeroSlider />
      </PageContainer>
      <Featured />
      {/* =========== about section ================ */}
      <AboutSection />
      {/* ========== services section ============ */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Our Services</h6>
              <h2 className="section__title">What We Offer</h2>
            </Col>

            <ServicesList />
          </Row>
        </Container>
      </section>
      {/* =========== why choose us section ============= */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h6 className="section__subtitle">Choose Us</h6>
              <h2 className="section__title">Why Choose Us?</h2>
            </Col>

            <Services />
          </Row>
        </Container>
      </section>

      {/* =========== testimonial section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h6 className="section__subtitle">What Our Clients Say</h6>
              <h2 className="section__title">Testimonials</h2>
            </Col>

            <Testimonial />
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <ContactHome />
          </Row>
        </Container>
      </section>
      <Footer />
      <BackTopBtn />
    </Helmet>
  );
};

export default Home;
