const firebaseConfig = {
  apiKey: "AIzaSyC-aDKDqZbRa-6GFEEYLnZr-1mj67xeAh8",
  authDomain: "bitradesinvenvestments.firebaseapp.com",
  projectId: "bitradesinvenvestments",
  storageBucket: "bitradesinvenvestments.appspot.com",
  messagingSenderId: "518955660270",
  appId: "1:518955660270:web:c2c8e296103260a4d354ff",
  measurementId: "G-QYPNGBX7LB",
};

export default firebaseConfig;
