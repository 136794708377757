import React, { Fragment, useState } from "react";
import Helmet from "../../../../Helmet/Helmet";
import "../../MainDash.css";
import CurrencyInput from "react-currency-input-field";
import { Dialog, Transition } from "@headlessui/react";

const Withdrawal = () => {
  const [transaction, setTransaction] = useState("");

  const prefix = "$ ";
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    e.preventDefault();
    const { value = "" } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, "");
    setValue(parsedValue);
  };

  const handleOnBlur = () => setValue(Number(value).toFixed(2));

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const areAllFieldsFilled = transaction !== "" && value !== "";

  return (
    <Helmet title="Withdraw">
      <div className="MainDash">
        <h2 className="mt-20  ml-5">Request for Withdrawal</h2>
      </div>
      <div class="min-h-screen  flex flex-col justify-center py-12 px-6 lg:px-8">
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-8 shadow rounded-lg sm:px-10">
            <form class="mb-0 space-y-8">
              <div>
                <label
                  for="fundCode"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Withdraw Code
                </label>
                <div class="mt-1">
                  <input
                    id="fundCode"
                    name="fundCode"
                    type="fundCode"
                    placeholder="GfRuw6DA3PSTb6sgvN"
                    disabled
                    required
                    className="px-4 py-2 text-lg font-medium border border-gray-300 rounded-lg w-full  "
                  />
                </div>
              </div>

              <div>
                <label
                  for="amount"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Amount
                </label>

                <div class="mt-1">
                  <CurrencyInput
                    prefix={prefix}
                    name="currencyInput"
                    id="currencyInput"
                    data-number-to-fixed="2"
                    data-number-stepfactor="100"
                    value={value}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    allowDecimals
                    decimalsLimit="2"
                    disableAbbreviations
                    required
                    className="px-4 py-2 text-lg font-medium border border-gray-300 rounded-lg w-full  "
                  />
                </div>
              </div>

              <div>
                <label
                  for="withdraw"
                  class="block text-sm font-medium text-gray-700"
                >
                  Withdraw Type
                </label>
                <div class="mt-1">
                  <select
                    name="withdraw"
                    id="withdraw"
                    required
                    class="w-full border-gray-300 rounded-lg shadow-sm"
                  >
                    <option value="">Please select</option>
                    <option value="small">Bitcoin</option>
                    <option value="medium">Ethereum</option>
                    <option value="large">USDT (TRC20)</option>
                  </select>
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium text-[#000D67]"
                >
                  Description
                </label>
                <div class="mt-1">
                  <input
                    id="transaction"
                    name="transaction"
                    type="transaction"
                    value={transaction}
                    onChange={(e) => setTransaction(e.target.value)}
                    required
                    className="px-4 py-2 text-sm text-[#000D67] font-bold border border-gray-300 rounded-lg w-full  "
                  />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={openModal}
                  disabled={!areAllFieldsFilled}
                  class="w-full cursor-not-allowed  flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Withdraw
                </button>
              </div>

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-2/3 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Withdrawal Processing
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Your withdrawal is processing, payments will be
                              made within 24 hours. Any delay please contact
                              support with proof of payment.
                            </p>
                          </div>

                          <div className="mt-4">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                              Got it, thanks!
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </form>
          </div>
        </div>
      </div>
    </Helmet>
  );
};

export default Withdrawal;
