import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-sectionTop.css";
import aboutImg from "../../assets/all-images/customers.jpg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">BITRADES INVESTMENTS</h4>
              <h2 className="section__title">
                A Certified Finance Investment Company
              </h2>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-shield-star-line"></i> 23,564 Total Investors
                </p>
                <p className="section__description d-flex align-items-center gap-2">
                  4.5<i class="ri-star-fill"></i> <i class="ri-star-fill"></i>
                  <i class="ri-star-fill"></i>
                  <i class="ri-star-fill"></i>
                  <i class="ri-star-half-fill"></i>(11254 Rating)
                </p>
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-user-star-fill"></i> 21,356 Reviews
                </p>
              </div>
              <h4 className="section__subtitle">About Us</h4>
              <p className="section__description">
                Bitrades Investments is a believed expert in the advanced cash
                venture area consolidated on 18 april 2016 as Private Limited
                Company. Our foundation permits expected investors to build
                their investment portfolio comprising of digital Assets,
                Management consultancy other than monetary administration. We
                assist our clients in grasping investment information. Our
                organization provides assurance, productivity, and
                dependability, which is why we're not afraid to take on any
                ambitious projects and do them quickly and without quality
                compromise. Our assurances protect investments against
                noncommercial risks and can help financial supporters acquire
                access to subsidizing sources with pre-negotiated financial
                terms.
              </p>
              <h4 className="section__subtitle">Our values</h4>
              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Reliability
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Transparency
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Consistency
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Flexibility
                </p>
              </div>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
