import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Navbar from "../components/Header/Navbar";
import Footer from "../components/Footer/Footer";
import Helmet from "../components/Helmet/Helmet";

import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  Button,
  makeStyles,
  Card,
  CardContent,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { useAuth } from "../contexts/AuthContext";

const PasswordRes = () => {
  const classes = useStyles();

  const override = `
        display: block;
        margin-left: 100px;
        border-color: red;
    `;

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPassword = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  function useQuery() {
    const location = useLocation();
    return new URLSearchParams(location.search);
  }

  const { resetPassword } = useAuth();
  const query = useQuery();
  query.get("mode");
  query.get("oobCode");
  query.get("continueUrl");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await resetPassword(query.get("oobCode"), newPassword);
      setTimeout(() => {
        toast.success("Reset Password Successful");
        setTimeout(() => {
          navigate("/Login");
        }, 2000);
      }, 2000);
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== newPassword) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [newPassword]);

  return (
    <Helmet title="Reset Password">
      <Navbar />
      <section>
        <Container component="main" maxWidth="xs">
          <Card className={classes.card}>
            <CardContent>
              <ToastContainer />
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                </Avatar>
                <Typography component="h1" variant="h6">
                  Reset Password
                </Typography>
                <ValidatorForm
                  onSubmit={handleSubmit}
                  onError={(errors) => {
                    for (const err of errors) {
                      console.log(err.props.errorMessages[0]);
                    }
                  }}
                  className={classes.form}
                >
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Password"
                    onChange={handleNewPassword}
                    name="password"
                    value={newPassword}
                    type="password"
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    autoComplete="off"
                  />

                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Confirm Password"
                    onChange={handleConfirmNewPassword}
                    name="password"
                    value={confirmNewPassword}
                    type="password"
                    validators={["isPasswordMatch", "required"]}
                    errorMessages={[
                      "password mismatch",
                      "this field is required",
                    ]}
                    autoComplete="off"
                  />

                  {loading ? (
                    <ScaleLoader
                      css={override}
                      size={150}
                      color={"#8360c3"}
                      loading={loading}
                    />
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      disabled={loading}
                    >
                      Reset Password
                    </Button>
                  )}
                </ValidatorForm>
              </div>
            </CardContent>
          </Card>
        </Container>
      </section>
      <Footer />
    </Helmet>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#8360c3",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    background: "linear-gradient(45deg, #2ebf91 30%, #8360c3 90%)",
    margin: theme.spacing(3, 0, 2),
    color: "#fff",
  },
  card: {
    marginTop: "60px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  pointer: {
    cursor: "pointer",
    color: "red",
  },
}));

export default PasswordRes;
