import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/featured.css";
import { Link } from "react-router-dom";
import Translate from "./MainDash/translate";

const Featured = () => {
  const [data, setData] = useState(null);

  const url =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=6&page=1&sparkline=false";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error("Failed to fetch data:", error.message);
      }
    };

    fetchData();
  }, []);

  if (!data) return null;

  return (
    <div className="featured">
      <div className="container">
        {/* Left */}
        <div className="left">
          <Translate />
          <h2>Explore and Earn top Crypto's Like Bitcoin, Ethereum and more</h2>
          <Link to="/register">
            <button className="btnf">Get Started</button>
          </Link>
        </div>

        {/* Right */}

        <div className="right">
          {data.slice(0, 6).map((coin, index) => (
            <div className="card" key={index}>
              <div className="top">
                <img src={coin.image} alt={coin.name} />
              </div>
              <div>
                <h5>{coin.name}</h5>
                <p>${coin.current_price.toLocaleString()}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Featured;
