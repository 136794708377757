import React, { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase";
import { useAuth } from "../../../../contexts/AuthContext";
import "./widget.scss"; // Import external stylesheet

const Widget = ({ type }) => {
  const { currentUser } = useAuth();
  const [toWithdrawals, setTotalWithdrawals] = useState(0);

  useEffect(() => {
    // Ensure currentUser is defined before proceeding
    if (currentUser && currentUser.uid) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        const { totalWithdrawals } = doc.data();
        setTotalWithdrawals(totalWithdrawals);
      });

      return () => unsub();
    }
  }, [currentUser]);

  // Render the widget content only when accountBal is available
  return (
    toWithdrawals !== 0 && (
      <div className="widget">
        <div className="left">
          <span className="title">Total Withdrawls</span>
          <span className="counter">
            {toWithdrawals !== 0 && "$"} {toWithdrawals}
          </span>
          {/* Conditionally render link if available */}
          {/* {data.link && <span className="link">{data.link}</span>} */}
        </div>
        <div className="right">
          <div className="percentage positive"></div>
          {/* Icon */}
          <img
            src="https://img.icons8.com/external-stickers-smashing-stocks/50/000000/external-Withdrawal-sale-and-cashback-refund-stickers-smashing-stocks.png"
            alt="Withdrawal Icon"
          />
        </div>
      </div>
    )
  );
};

export default Widget;
