import React, { useState, useEffect } from "react";
import Helmet from "../../components/Helmet/Helmet";
import MainDash from "../../components/UI/MainDash/MainDash";
import Sidebar from "../../components/UI/Sidebar";
import "../../styles/dashboard.css";
import axios from "axios";

const DashBoard = () => {
  const [coins, setCoins] = useState([]);
  const url =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=true";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setCoins(response.data);
      } catch (error) {
        // Handle errors here
        console.error("Failed to fetch data:", error.message);
      }
    };

    fetchData();
  }, [url]);

  return (
    <Helmet title="Dash Board">
      <div className="App">
        <div className="AppGlass">
          <Sidebar />
          <MainDash coins={coins} />
        </div>
      </div>
    </Helmet>
  );
};

export default DashBoard;
