import React, { useEffect, useState } from "react";
import Helmet from "../../../../Helmet/Helmet";
import "../../MainDash.css";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { useAuth } from "../../../../../contexts/AuthContext";

const Records = () => {
  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();
  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        dbStateRef.current = doc.data();
      });

      return () => unsub();
    }
  }, [currentUser]);

  const itemsPerPage = 5; // Change this to adjust the number of items per page

  const getPaginatedData = (data) => {
    const pageCount = Math.ceil(data.length / itemsPerPage);
    const paginatedData = Array.from({ length: pageCount }, (_, i) =>
      data.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage)
    );
    return paginatedData;
  };

  const rows = getPaginatedData(
    Array.from({ length: 20 }, (_, i) => ({
      id: i + 1,
      date: dbState?.[`sdate${i + 1}`],
      amount: dbState?.[`pamount${i + 1}`],
      status: dbState?.[`pstatus${i + 1}`],
      type: dbState?.[`ptype${i + 1}`],
    }))
  );


  const Pagination = ({ currentPage, totalPage, onPageChange }) => (
    <div className="flex justify-center mt-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="mr-2 px-3 py-1 bg-gray-200 text-gray-600 rounded"
      >
        Previous
      </button>
      <span className="mx-2">
        Page {currentPage} of {totalPage}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPage}
        className="ml-2 px-3 py-1 bg-gray-200 text-gray-600 rounded"
      >
        Next
      </button>
    </div>
  );

  const [pcurrentPage, setDCurrentPage] = useState(1);

  return (
    <Helmet title="Profit Records">
      <div className="MainDash">
        <h2 className="mt-20  ml-5">Your Trading History</h2>
      </div>
      <div className="p-12">
        <div className="relative overflow-x-auto  w-fill">
          <div className="pb-4 flex items-center"></div>
          <table className="w-full text-sm text-left text-gray-500 table-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100">
              <tr>
                <th scope="col" className="px-6 py-3">
                  AMOUNT
                </th>
                <th scope="col" className="px-6 py-3">
                  PROFITS
                </th>
                <th scope="col" className="px-6 py-3">
                  TYPE
                </th>
                <th scope="col" className="px-6 py-3">
                  DATE CREATED
                </th>
              </tr>
            </thead>
            <tbody>
              {rows[pcurrentPage - 1]?.map((transaction) => (
                <tr key={transaction.id} className="bg-white border-b">
                  <td className="px-6 py-4">{transaction.date}</td>
                  <td className="px-6 py-4">{transaction.amount}</td>
                  <td className="px-6 py-4">{transaction.status}</td>
                  <td className="px-6 py-4">{transaction.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={pcurrentPage}
            totalPage={rows.length}
            onPageChange={setDCurrentPage}
          />
        </div>
      </div>
    </Helmet>
  );
};

export default Records;
