import React, { useState, useCallback } from "react";
import "../../styles/sidebar.css";
import { UilSignOutAlt, UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { BrandLogo } from "../brandLogo";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { SidebarData } from "./Data";

const Sidebar = React.memo(() => {
  const [selected, setSelected] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [error, setError] = useState("");

  const sidebarVariants = {
    true: { left: "0" },
    false: { left: "-60%" },
  };

  const handleClick = useCallback(
    (index, item) => {
      setSelected(index);
      navigate(item.path);
    },
    [navigate]
  );

  const handleLogout = useCallback(async () => {
    setError("");
    try {
      await logout();
      navigate("/Login");
    } catch {
      setError("Failed to log out");
    }
  }, [logout, navigate]);

  const toggleExpanded = () => setExpanded(!expanded);

  const MenuItemTop = ({ item, index }) => {
    const Icon = item.icon; // Access the icon component from props
    return (
      <div
        className={`menuItemTop ${selected === index ? "active" : ""}`}
        onClick={() => handleClick(index, item)}
      >
        <Icon /> {/* Render the icon component */}
        {item.heading}
      </div>
    );
  };

  return (
    <>
      <div
        className="bars"
        style={{ left: expanded ? "60%" : "5%" }}
        onClick={toggleExpanded}
      >
        <UilBars />
      </div>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        {/* logo */}
        <div className="logos ml-3">
          <BrandLogo />
        </div>

        <div className="menuTop">
          {SidebarData.map((item, index) => (
            <MenuItemTop key={index} item={item} index={index} />
          ))}
          {/* signoutIcon */}
          <div className="pl-10">
            <button className="btnlgt" onClick={handleLogout}>
              <UilSignOutAlt />
              <span>logout</span>
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
});

export default Sidebar;
