import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { motion } from "framer-motion";
import LogoImg from "../assets/all-images/logoImages/logo.png";

const Spinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <motion.img
      src={LogoImg}
      alt="Loading..."
      animate={{ rotate: 360 }}
      transition={{ duration: 5, loop: Infinity }}
      style={{
        width: 80,
        height: 80,
      }}
    />
  </div>
);

const PrivateRoute = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return loading ? (
    <Spinner />
  ) : currentUser ? (
    <Outlet />
  ) : (
    <Navigate to="/Login" replace />
  );
};

export default PrivateRoute;
