import React from "react";
import AccountBalance from "../widgets/AccountBalance";
import TotalDeposit from "../widgets/TotalDeposit";
import TotalProfit from "../widgets/TotalProfit";
import TotalReferralBonus from "../widgets/TotalReferralBonus";
import TotalWithdrawals from "../widgets/TotalWithdrawals";

const components = [
  { component: AccountBalance, type: "accountBalance" },
  { component: TotalDeposit, type: "totalDeposit" },
  { component: TotalProfit, type: "totalProfit" },
  { component: TotalWithdrawals, type: "totalWithdrawals" },
];

const Cards = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        {components.map((item, index) => (
          <div className="col-md-3 pt-3 pb-3" key={index}>
            <item.component type={item.type} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
