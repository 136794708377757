// Sidebar imports
import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilSett
} from "@iconscout/react-unicons";

// Analytics Cards imports
import {
  UilUsdSquare,
  UilMoneyWithdrawal,
  UilMoneyWithdraw,
} from "@iconscout/react-unicons";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

// Sidebar Data
export const SidebarData = [
  {
    icon: UilEstate,
    path: "/dashboard",
    heading: "Dashboard",
  },

  {
    icon: UilMoneyWithdraw,
    path: "/deposit",
    heading: "Deposit",
  },

  {
    icon: UilChart,
    path: "/transactionHistory",
    heading: "Transaction History",
  },

  {
    icon: UilMoneyWithdrawal,
    path: "/withdraw",
    heading: "Withdraw",
  },

  {
    icon: UilUsersAlt,
    heading: "Change Password",
    path: "/change-password",
  },
];
