import React from "react";

import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 5000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content">
            <h3 className="text-light mb-3">
              Your access to the global financial markets
            </h3>
            <h1 className="text-light mb-4">From the Best in the Industry</h1>
            <Link to="/Login">
              <button className="btns">GET STARTED</button>
            </Link>
          </div>
        </Container>
      </div>
      <div className="slider__item slider__item-02 mt0">
        <Container>
          <div className="slider__content">
            <h4 className="text-light mb-3">AT BITRADES INVESTMENTS</h4>
            <h1 className="text-light mb-4">YOUR PROFIT IS ASSURED</h1>
            <Link to="/Login">
              <button className="btns">LOGIN</button>
            </Link>
          </div>
        </Container>
      </div>
      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content">
            <h4 className="text-light mb-3">AT BITRADES INVESTMENTS</h4>
            <h1 className="text-light mb-4">YOUR CAPITAL IS SECURED</h1>
            <Link to="/register">
              <button className="btns">REGISTER</button>
            </Link>
          </div>
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;
