import React, { useEffect, useState } from "react";
import Helmet from "../../Helmet/Helmet";
import Cards from "./cards/Cards";
import CoinSearch from "./CoinSearch";
import { db } from "../../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "../../../contexts/AuthContext";
import "./MainDash.css";
import Translate from "./translate";

const MainDash = ({ coins }) => {
  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();
  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        dbStateRef.current = doc.data();
        setDbState(dbStateRef.current);
      });

      return () => unsub();
    }
  }, [currentUser]);

  let user = dbState?.userName;

  return (
    <Helmet title="DashBoard">
      <div className="MainDash">
        <h2 className="mt-20 ml-0">Welcome {user}</h2>
        <Translate />
        <h2 className="mt-3 ml-5">Dash Board</h2>
        <Cards />
        <CoinSearch coins={coins} />
      </div>
    </Helmet>
  );
};

export default MainDash;
