import React from "react";
import Helmet from "../../components/Helmet/Helmet";
import ChangePassword from "../../components/UI/MainDash/widgets/pages/changePassword";
import Sidebar from "../../components/UI/Sidebar";
import "../../styles/dashboard.css";

const ChangePasswords = () => {
  return (
    <Helmet title="Deposit Funds">
      <div className="App">
        <div className="AppGlass">
          <Sidebar />
          <section>
            <ChangePassword />
          </section>
        </div>
      </div>
    </Helmet>
  );
};

export default ChangePasswords;
